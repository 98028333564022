import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MarkdownModule } from '@x/common/markdown';
import { OperationModule } from '@x/common/operation';
import { DashboardCoreModule } from '@x/dashboard/core';
import { AiChatComponent } from './components/ai-chat/ai-chat.component';
import { AiContextService } from './services/ai-context.service';
import { AiPanelService } from './services/ai-panel.service';
@NgModule({
  imports: [CommonModule, FormsModule, DashboardCoreModule, MarkdownModule, OperationModule],
  declarations: [AiChatComponent],
  providers: [AiContextService, AiPanelService],
  exports: [AiChatComponent],
})
export class AiModule {}
