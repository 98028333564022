<ng-container [xOperation]="operation$ | async">
  <header>
    <div class="d-flex align-items-center">
      <fa-icon icon="brain-circuit" size="xl"></fa-icon>
      <h2 class="ms-1">Elly</h2>
    </div>

    <div class="actions">
      <button
        matTooltip="Start a new chat"
        mat-stroked-button
        class="chat-action"
        (click)="newChat()"
      >
        <fa-icon icon="plus"></fa-icon>
      </button>
    </div>
  </header>

  <section class="message-list" #messageList>
    <div class="chat-spacer"></div>
    @for (item of messages; track $index) {
      @if (item.role === 'user') {
        <div class="message user">
          <x-markdown-view [source]="item.message"></x-markdown-view>
        </div>
      } @else {
        <div class="message ai">
          <fa-icon icon="brain-circuit" xColor="grey"></fa-icon>
          <x-markdown-view [source]="item.message"></x-markdown-view>
        </div>
      }
    } @empty {
      <div class="empty-message">No messages yet</div>
    }
    <x-dashboard-errors *xOperationError="let error" [errors]="[error]"></x-dashboard-errors>
  </section>

  <section class="text-actions">
    <textarea
      matInput
      placeholder="Ask me anything..."
      (keydown.enter)="sendMessage($event)"
      [(ngModel)]="currentMessage"
      rows="3"
    ></textarea>
    <button mat-stroked-button class="chat-action" (click)="sendMessage($event)">
      @if (!pendingResponse) {
        <fa-icon icon="paper-plane-top" xColor="grey" matTooltip="Send message"></fa-icon>
      } @else {
        <fa-icon class="loader" icon="spinner" matTooltip="Waiting for response..."></fa-icon>
      }
    </button>
  </section>
</ng-container>
