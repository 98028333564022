import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TMessageDetail, TMessageItem } from '@x/ai/client';
import { ObservableOperation, OperationObserverService } from '@x/common/operation';
import { LocalStorage } from '@x/common/storage';
import { AiChatService } from 'libs/ai/src/client/services/ai-chat.service';
import { Subscription, finalize, map, tap } from 'rxjs';
import { AiContextService } from '../../services/ai-context.service';
import { AiPanelService } from '../../services/ai-panel.service';

@Component({
  selector: 'x-ai-chat',
  templateUrl: 'ai-chat.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  host: {
    class: 'x-ai-chat',
  },
})
export class AiChatComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  @ViewChild('messageList') private messageListRef: ElementRef;

  currentMessage: string = '';
  messages: TMessageItem[] = [];

  pendingResponse = false;

  operation$: ObservableOperation;

  constructor(
    private readonly changeRef: ChangeDetectorRef,
    private aiChatService: AiChatService,
    private store: LocalStorage,
    private aiContext: AiContextService,
    private aiPanelService: AiPanelService,
    private operations: OperationObserverService,
  ) {
    this.subscriptions.add(
      this.aiPanelService.sidenavState$.subscribe((isOpen) => {
        this.onSidenavStateChange(isOpen);
      }),
    );
  }

  ngOnInit(): void {
    const storedThread = this.store.getItem('ai-thread');

    if (storedThread) {
      this.operation$ = this.operations.observe(
        this.aiChatService.getMessagesGQL(storedThread).pipe(
          map((response) =>
            response.data.getMessages.messages
              .map((message: any) => ({
                message: message.message,
                role: message.role,
              }))
              .reverse(),
          ),
          tap((messages) => {
            this.messages = messages;
            this.changeRef.markForCheck();
          }),
        ),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSidenavStateChange(isOpen: boolean) {
    if (isOpen) {
      this.scrollToBottom();
    }
  }

  sendMessage(event: Event) {
    if (this.pendingResponse) return;
    event.preventDefault();

    const context = this.aiContext.getCurrentContext();

    if (!this.currentMessage || this.currentMessage.trim() === '') return;

    this.addMessage({ message: this.currentMessage, role: 'user' });
    this.pendingResponse = true;
    const thread = this.store.getItem('ai-thread');

    if (thread)
      this.operation$ = this.operations.observe(
        this.aiChatService
          .sendMessageGQL({
            message: this.currentMessage,
            threadId: thread,
            messageContext: context,
          })
          .pipe(
            finalize(() => {
              this.pendingResponse = false;
            }),
            tap((response) => {
              this.addMessage(response.data.sendMessage);
            }),
          ),
      );
    else
      this.operation$ = this.operations.observe(
        this.aiChatService
          .sendMessageGQL({ message: this.currentMessage, messageContext: context })
          .pipe(
            finalize(() => {
              this.pendingResponse = false;
            }),
            tap((response) => {
              this.store.setItem('ai-thread', response.data.sendMessage.threadId);
              this.addMessage(response.data.sendMessage);
            }),
          ),
      );

    this.currentMessage = '';
  }

  private addMessage(message: TMessageDetail) {
    this.messages.push({ message: message.message, role: message.role });
    this.changeRef.markForCheck();
    this.scrollToBottom();
  }

  newChat() {
    this.store.removeItem('ai-thread');
    this.messages = [];
    this.currentMessage = '';
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      if (this.messageListRef?.nativeElement) {
        this.messageListRef.nativeElement.scrollTop =
          this.messageListRef.nativeElement.scrollHeight;
      }
    });
  }
}
