import { Injectable, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subject, Subscription } from 'rxjs';

@Injectable({providedIn: 'root'})
export class AiPanelService {
  aiSidenav: MatSidenav | null = null;
  
  private sidenavState = new Subject<boolean>();
  sidenavState$ = this.sidenavState.asObservable();


  registerSidenav(aiSidenav: MatSidenav) {
    this.aiSidenav = aiSidenav;

    this.aiSidenav.openedChange.subscribe((isOpen) => {
      this.sidenavState.next(isOpen); // Emit value through the Subject
    });
  }


}
